<template>
  <div>
    <form class="form" @submit.prevent="submitForm" novalidate="true" autocomplete="off">
      <div class="form__group--three">
        <label for="amount" class="form__label">
          <input
            type="tel"
            id="amount"
            name="amount"
            placeholder="Amount"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @focus="showAmounts = true"
            v-model.lazy="$v.form.amount.$model"
            :class="{
              'is-invalid': submitted && $v.form.amount.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.amount.required"
          >Account number is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.amount.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <app-amount-suggest
        v-if="showAmounts"
        @selectedAmount="setAmount"
        type="localTransfer"
      ></app-amount-suggest>

      <div class="form__group--three">
        <label for="transferSource" class="form__label">
          <input
            readonly
            type="tel"
            id="transferSource"
            name="transferSource"
            placeholder="From"
            class="form__input hide-caret"
            @focus="showFromModal = true"
            autocomplete="off"
            v-model.lazy="$v.form.transferSource.$model"
            :class="{
              'is-invalid': submitted && $v.form.transferSource.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.transferSource.required"
          >Source account is required</small
        >
        <img src="../../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="form__group--three">
        <label for="destinationAccount" class="form__label">
          <input
            readonly
            type="tel"
            id="destinationAccount"
            name="destinationAccount"
            placeholder="To"
            class="form__input hide-caret"
            @focus="showToModal = true"
            v-model.lazy="$v.form.destinationAccount.$model"
            autocomplete="off"
            :class="{
              'is-invalid': submitted && $v.form.destinationAccount.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.destinationAccount.required"
          >Destination account is required</small
        >
        <img src="../../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="form__group--three">
        <label for="budgetCategory" class="form__label">
          <select
            name="budgetCategory"
            id="budgetCategory"
            class="form__input form__select"
            v-model.lazy="form.budgetCategory"
          >
            <option disabled value="">Budget Category</option>
            <option
              v-for="(category, index) in categories"
              :value="category.value"
              :key="index"
            >
              {{ category.text }}
            </option>
          </select>
        </label>
      </div>

      <div class="form__group--six">
        <div class="form__radio form__radio--one">
          <div class="form__radio--box">
            <input
              type="radio"
              value="Now"
              id="now"
              name="now"
              @change="clearSendType('now')"
              v-model.lazy="$v.form.sendType.$model"
            />
            <label for="now" class="form__label">Send now</label>
          </div>
          <div class="form__radio--box">
            <input
              type="radio"
              value="Later"
              id="later"
              name="later"
              @change="clearSendType('later')"
              v-model.lazy="$v.form.sendType.$model"
            />
            <label for="later" class="form__label">Later</label>
          </div>
          <div class="form__radio--box">
            <input
              type="radio"
              value="Repeat"
              id="repeat"
              name="repeat"
              @change="clearSendType('repeat')"
              v-model.lazy="$v.form.sendType.$model"
            />
            <label for="repeat" class="form__label">Repeat</label>
          </div>
        </div>

        <div class="send-date" v-if="laterDateShow !== ''">
          <i class="el-icon-date"></i>
          <p>
            <span>Send on</span> <span>{{ laterDateShow }}</span>
          </p>
          <button class="btn btn__transparent-red-two" @click="editLaterDate">
            Change
          </button>
        </div>

        <div
          class="send-date"
          v-if="repeatStartDateShow !== '' && repeatEndDateShow !== ''"
        >
          <i class="el-icon-date"></i>
          <p>
            <span>Reoccuring {{ form.repeating }}</span>
            <span>Start on {{ repeatStartDateShow }}</span>
            <span>Ends on {{ repeatEndDateShow }}</span>
          </p>
          <button class="btn btn__transparent-red-two" @click="editRecurringDate">
            Change
          </button>
        </div>

        <small class="error" v-if="submitted && !$v.form.sendType.required"
          >Send type is required</small
        >
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Send Money</span>
      </button>
    </form>

    <app-source-accounts
      v-if="showFromModal"
      @sourceDetails="setSourceDetails"
      :showFromModal="showFromModal"
      @close="showFromModal = false"
    ></app-source-accounts>

    <app-destination-account
      v-if="showToModal"
      @destinationDetails="setDestinationDetails"
      :showToModal="showToModal"
      :filteredUserAccounts="filteredUserAccounts"
      @close="showToModal = false"
    ></app-destination-account>

    <app-datetime-selector
      v-if="showDateTimeModal"
      :showDateTimeModal="showDateTimeModal"
      :laterDate="form.laterDate"
      @close="showDateTimeModal = false"
      @selectedDate="setDate"
    ></app-datetime-selector>

    <app-datetimerange-selector
      v-if="showDateTimeRangeModal"
      :showDateTimeRangeModal="showDateTimeRangeModal"
      :recurringDetails="form.recurringDetails"
      @close="showDateTimeRangeModal = false"
      @selectedDateRange="setDateRange"
    ></app-datetimerange-selector>

    <app-pin-modal
      ref="otpInput"
      v-if="showPinModal"
      :showPinModal="showPinModal"
      @close="showPinModal = false"
      @userPin="setUserPin"
    ></app-pin-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

import AmountSelector from "@/components/Transfers/Widgets/AmountSelector.vue";
import SourceAccountsFcy from "@/components/Transfers/Widgets/SourceAccountsFcy.vue";
import DestinationAccountFcy from "@/components/Transfers/ForeignTransfers/DestinationAccountFcy.vue";
import DateTimeSelector from "@/components/Transfers/Widgets/DateTimeSelector.vue";
import DateTimeRangeSelector from "@/components/Transfers/Widgets/DateTimeRangeSelector.vue";
import PinModal from "@/widgets/PinModal.vue";

export default {
  components: {
    "app-amount-suggest": AmountSelector,
    "app-source-accounts": SourceAccountsFcy,
    "app-destination-account": DestinationAccountFcy,
    "app-datetime-selector": DateTimeSelector,
    "app-datetimerange-selector": DateTimeRangeSelector,
    "app-pin-modal": PinModal,
  },
  computed: mapState(["userFcyAccounts", "user", "loading"]),
  data() {
    return {
      passwordFieldType: "password",
      submitted: false,
      showAmounts: false,
      selectedAccount: "",
      showPinModal: false,
      showFromModal: false,
      showToModal: false,
      showDateTimeModal: false,
      showDateTimeRangeModal: false,
      duplicateStatus: false,
      filteredUserAccounts: [],
      laterDateShow: "",
      repeatStartDateShow: "",
      repeatEndDateShow: "",
      form: {
        amount: "",
        transferSource: "",
        accountType: "",
        debitNuban: "",
        debitCurrency: "",
        budgetCategory: "",
        sendType: "",
        laterDate: "",
        isFromWallet: false,
        isToWallet: false,
        sendToMySelf: false,
        isFx: false,
        recurringDetails: "",
        repeatStartDate: "",
        repeatEndDate: "",
        repeating: "",
        repeatingFrequency: "",
        pin: "",
      },
      categories: [
        { text: "General", value: "General" },
        { text: "Family", value: "Family" },
        { text: "Bills", value: "Bills" },
        { text: "Eating Out", value: "Eating Out" },
        { text: "Entertainment", value: "Entertainment" },
        { text: "Expenses", value: "Expenses" },
        { text: "Finances", value: "Finances" },
        { text: "Groceries", value: "Groceries" },
        { text: "Health", value: "Health" },
        { text: "Holidays", value: "Holidays" },
        { text: "Shopping", value: "Shopping" },
        { text: "Transport", value: "Transport" },
      ],
    };
  },
  validations: {
    form: {
      amount: { required, numeric },
      transferSource: { required },
      destinationAccount: { required },
      sendType: { required },
    },
  },
  watch: {
    selectedAccount: function (newValue) {
      if (newValue !== "") {
        this.form.transferSource = `${newValue.NUBAN} | ${newValue.ACCT_TYPE}`;

        this.filteredUserAccounts = this.userFcyAccounts.filter(
          (item) => item !== newValue
        );
      }
    },
    "form.accountType": function (newValue) {
      if (newValue === "Wallet") {
        this.form.isFromWallet = true;
      }
    },
    "form.creditNuban": function (newValue) {
      if (newValue.length === 11) {
        this.form.isToWallet = true;
      }
    },
    "form.debitCurrency": function (newValue) {
      if (newValue !== "NGN") {
        this.form.isFx = true;
      }

      this.userFcyAccounts.forEach((item) => {
        if (item.debitNUBAN === this.form.creditNuban) {
          this.form.sendToMySelf = true;
        }
      });
    },
    showPinModal: function (newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.fixChromeMask();
        });
      }
    },
  },
  methods: {
    setAmount(value) {
      this.form.amount = value;
      this.showAmounts = false;
    },
    setSourceDetails(account) {
      this.form.debitNuban = account.NUBAN;
      this.form.debitCurrency = account.Currency_Code;
      this.form.accountType = account.ACCT_TYPE;
      this.selectedAccount = account;
      this.showFromModal = false;
    },
    setDestinationDetails(value) {
      this.showToModal = false;
      this.form = { ...this.form, ...value };
    },
    setDate(value) {
      this.form.laterDate = value;
      this.laterDateShow = dayjs(value).format("DD/MM/YYYY hh:mm:ss a");
      this.showDateTimeModal = false;
    },
    editLaterDate(e) {
      e.preventDefault();
      this.showDateTimeModal = true;
    },
    setDateRange(value) {
      this.form.repeatStartDate = value.startDate;
      this.form.repeatEndtDate = value.endDate;
      this.form.repeating = value.repeating;
      this.form.recurringDetails = value;

      switch (this.form.repeating) {
        case "Daily":
          this.form.repeatingFrequency = 1;
          break;
        case "Weekly":
          this.form.repeatingFrequency = 2;
          break;
        case "Monthly":
          this.form.repeatingFrequency = 3;
          break;

        default:
          break;
      }

      this.repeatStartDateShow = dayjs(value.startDate).format("DD/MM/YYYY hh:mm:ss a");
      this.repeatEndDateShow = dayjs(value.endDate).format("DD/MM/YYYY hh:mm:ss a");
      this.showDateTimeRangeModal = false;
    },
    editRecurringDate(e) {
      e.preventDefault();
      this.showDateTimeRangeModal = true;
    },
    clearSendType(value) {
      switch (value) {
        case "now":
          this.laterDateShow = "";
          this.repeatStartDateShow = "";
          this.repeatEndDateShow = "";
          break;
        case "later":
          this.laterDateShow = "";
          this.repeatStartDateShow = "";
          this.repeatEndDateShow = "";
          this.showDateTimeModal = true;
          break;
        case "repeat":
          this.laterDateShow = "";
          this.repeatStartDateShow = "";
          this.repeatEndDateShow = "";
          this.showDateTimeRangeModal = true;
          break;

        default:
          break;
      }
    },
    sendSterlingToSterling() {
      let payload = {
        debitNuban: this.form.debitNuban,
        debitCurrency: this.form.debitCurrency,
        creditNuban: this.form.creditNuban,
        creditCurrency: this.form.creditCurrency,
        amount: String(this.form.amount),
        narrations: this.form.description,
        transactionpin: this.form.pin,
        Category: this.form.budgetCategory,
        smId: this.user.profileInfo.SMID,
        DuplicateConfirmed: this.duplicateStatus,
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleSterlingToSterlingTransfer(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.sendSterlingToSterling();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    sendSterlingToInterbank() {
      let payload = {
        nesessionid: this.form.otherAccountInfo.sessionid,
        beneficiaryname: this.form.otherAccountInfo.accountname,
        beneficiarybankverificationnumber: this.form.otherAccountInfo.bvn,
        beneficiarykyclevel: this.form.otherAccountInfo.kyclevel,
        destinationbankcode:
          this.form.bankCodes[this.form.banks.indexOf(this.form.beneficiaryBank)] ||
          this.form.savedBenBankCode,
        fromaccount: this.form.debitNuban,
        toaccount: this.form.creditNuban,
        amount: +this.form.amount,
        paymentreference: this.form.description,
        transactionpin: this.form.pin,
        Category: this.form.budgetCategory,
        smId: this.user.profileInfo.SMID,
        DuplicateConfirmed: this.duplicateStatus,
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleSterlingToInterbankTransfer(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.sendSterlingToInterbank();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleIntrabankSchedulePayment() {
      let payload = {
        FromAccount: this.form.debitNuban,
        ToAccount: this.form.creditNuban,
        DebitCurrency: this.form.debitCurrency,
        CreditCurrency: this.form.creditCurrency,
        Amount: +this.form.amount,
        IsFromWallet: this.form.isFromWallet,
        ScheduledDate: this.form.laterDate,
        Narration: this.form.description,
        TransactionPin: this.form.pin,
        Category: this.form.budgetCategory,
        smId: this.user.profileInfo.SMID,
        IsToWallet: this.form.isToWallet,
        IsFX: this.form.isFx,

        SendToMySelf: this.form.sendToMySelf,

        DuplicateConfirmed: this.duplicateStatus,
      };

      this.$store.commit("toggleLoading", true);

      api
        .scheduleIntrabankPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.handleIntrabankSchedulePayment();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleInterbankSchedulePayment() {
      let payload = {
        FromAccount: this.form.debitNuban,
        ToAccount: this.form.creditNuban,
        DestinationBankCode:
          this.form.bankCodes[this.form.banks.indexOf(this.form.beneficiaryBank)] ||
          this.form.savedBenBankCode,
        Amount: +this.form.amount,
        Narration: this.form.description,
        TransactionPin: this.form.pin,
        Category: this.form.budgetCategory,
        smId: this.user.profileInfo.SMID,
        ScheduledDate: this.form.laterDate,
        IsFromWallet: this.form.isFromWallet,

        DuplicateConfirmed: this.duplicateStatus,
      };

      this.$store.commit("toggleLoading", true);

      api
        .scheduleInterbankPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.handleInterbankSchedulePayment();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleIntrabankRecurringPayment() {
      let payload = {
        FromAccount: this.form.debitNuban,
        ToAccount: this.form.creditNuban,
        DebitCurrency: this.form.debitCurrency,
        CreditCurrency: this.form.creditCurrency,
        Amount: +this.form.amount,
        IsFromWallet: this.form.isFromWallet,
        StartDate: this.form.repeatStartDate,
        EndDate: this.form.repeatEndtDate,
        Frequency: this.form.repeatingFrequency,
        Narration: this.form.description,
        TransactionPin: this.form.pin,
        Category: this.form.budgetCategory,
        smId: this.user.profileInfo.SMID,
        IsToWallet: this.form.isToWallet,
        IsFX: this.form.isFx,
        SendToMySelf: this.form.sendToMySelf,

        DuplicateConfirmed: this.duplicateStatus,
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleIntrabankRecurringPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.handleIntrabankRecurringPayment();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleInterbankRecurringPayment() {
      let payload = {
        FromAccount: this.form.debitNuban,
        ToAccount: this.form.creditNuban,
        DestinationBankCode:
          this.form.bankCodes[this.form.banks.indexOf(this.form.beneficiaryBank)] ||
          this.form.savedBenBankCode,
        Amount: +this.form.amount,
        IsFromWallet: this.form.isFromWallet,
        Narration: this.form.description,
        TransactionPin: this.form.pin,
        Category: this.form.budgetCategory,
        smId: this.user.profileInfo.SMID,
        StartDate: this.form.repeatStartDate,
        EndDate: this.form.repeatEndtDate,
        Frequency: this.form.repeatingFrequency,

        DuplicateConfirmed: this.duplicateStatus,
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleInterbankRecurringPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.handleInterbankRecurringPayment();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    fixChromeMask() {
      // Modal mask fix for chrome
      this.$refs.otpInput.$el.children[0].setAttribute("autocomplete", "new-password");
      const inputs = this.$refs.otpInput.$el.querySelectorAll(".otp-input");
      inputs.forEach((i) => i.setAttribute("autocomplete", "new-password"));
    },
    setUserPin(value) {
      this.form.pin = value;
      this.showPinModal = false;
      this.processRequest();
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      this.showPinModal = true;
    },
    processRequest() {
      this.$store.commit("toggleLoading", true);

      if (this.form.sendType === "Now") {
        if (
          this.form.accountType !== "Wallet" &&
          (this.form.beneficiaryType === "New Beneficiary" ||
            this.form.beneficiaryType === "Saved Beneficiary" ||
            this.form.selfBeneficiaryType === "Sterling")
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendSterlingToSterling();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendSterlingToSterling();
          }
        } else if (
          this.form.accountType !== "Wallet" &&
          (this.form.beneficiaryType === "Wallet" ||
            this.form.selfBeneficiaryType === "Wallet")
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendSterlingToWallet();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendSterlingToWallet();
          }
        } else if (
          this.form.accountType !== "Wallet" &&
          this.form.beneficiaryType === "GoMoney"
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.accountNumber,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: this.form.beneficiaryBank,
              beneficiaryBankCode: this.form.bankCodes[
                this.form.banks.indexOf(this.form.beneficiaryBank)
              ],
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendSterlingToInterbank();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendSterlingToInterbank();
          }
        } else if (
          this.form.accountType === "Wallet" &&
          (this.form.beneficiaryType === "New Beneficiary" ||
            this.form.beneficiaryType === "Saved Beneficiary" ||
            this.form.selfBeneficiaryType === "Sterling")
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendWalletToSterling();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendWalletToSterling();
          }
        } else if (
          this.form.accountType === "Wallet" &&
          (this.form.beneficiaryType === "Wallet" ||
            this.form.selfBeneficiaryType === "Wallet")
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendWalletToWallet();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendWalletToWallet();
          }
        } else if (
          this.form.accountType === "Wallet" &&
          this.form.beneficiaryType === "GoMoney"
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.accountNumber,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: this.form.beneficiaryBank,
              beneficiaryBankCode: this.form.bankCodes[
                this.form.banks.indexOf(this.form.beneficiaryBank)
              ],
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendWalletToInterbank();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendWalletToInterbank();
          }
        } else if (
          this.form.accountType !== "Wallet" &&
          (this.form.otherBeneficiaryType === "New Beneficiary" ||
            this.form.otherBeneficiaryType === "Saved Beneficiary")
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.accountNumber,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: this.form.beneficiaryBank,
              beneficiaryBankCode: this.form.bankCodes[
                this.form.banks.indexOf(this.form.beneficiaryBank)
              ],
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendSterlingToInterbank();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendSterlingToInterbank();
          }
        } else if (
          this.form.accountType === "Wallet" &&
          (this.form.otherBeneficiaryType === "New Beneficiary" ||
            this.form.otherBeneficiaryType === "Saved Beneficiary")
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.accountNumber,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: this.form.beneficiaryBank,
              beneficiaryBankCode: this.form.bankCodes[
                this.form.banks.indexOf(this.form.beneficiaryBank)
              ],
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.sendWalletToInterbank();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.sendWalletToInterbank();
          }
        }
      } else if (this.form.sendType === "Later") {
        if (
          this.form.beneficiaryType === "New Beneficiary" ||
          this.form.beneficiaryType === "Saved Beneficiary" ||
          this.form.selfBeneficiaryType === "Sterling" ||
          this.form.selfBeneficiaryType === "Wallet" ||
          this.form.beneficiaryType === "Wallet" ||
          this.form.beneficiaryType === "GoMoney"
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.handleIntrabankSchedulePayment();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.handleIntrabankSchedulePayment();
          }
        } else if (
          this.form.otherBeneficiaryType === "New Beneficiary" ||
          this.form.otherBeneficiaryType === "Saved Beneficiary"
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.handleInterbankSchedulePayment();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.handleInterbankSchedulePayment();
          }
        }
      } else if (this.form.sendType === "Repeat") {
        if (
          this.form.beneficiaryType === "New Beneficiary" ||
          this.form.beneficiaryType === "Saved Beneficiary" ||
          this.form.selfBeneficiaryType === "Sterling" ||
          this.form.selfBeneficiaryType === "Wallet" ||
          this.form.beneficiaryType === "Wallet" ||
          this.form.beneficiaryType === "GoMoney"
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.handleIntrabankRecurringPayment();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.handleIntrabankRecurringPayment();
          }
        } else if (
          this.form.otherBeneficiaryType === "New Beneficiary" ||
          this.form.otherBeneficiaryType === "Saved Beneficiary"
        ) {
          if (this.form.saveBeneficiary) {
            let payload = {
              userId: this.user.profileInfo.SMID,
              beneficiaryAccountNumber: this.form.beneficiaryDetails.NUBAN,
              beneficiaryAccountName: this.form.beneficiaryDetails.AccountTitle,
              beneficiaryBankName: "Sterling Bank",
              beneficiaryBankCode: "000001",
            };

            api
              .addBeneficiary(payload)
              .then((response) => {
                this.$store.commit("toggleLoading", false);
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                let responseCode = response.Code;

                if (responseStatus || responseCode === "USERBENEXIST001") {
                  this.handleInterbankRecurringPayment();
                } else {
                  this.$message({
                    showClose: true,
                    message: `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    type: "error",
                    duration: 10000,
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else {
            this.handleInterbankRecurringPayment();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
